import React from 'react';
import { IAny, ICoreContext, IGeneric, IImageData, IImageSettings, Image } from '@msdyn365-commerce/core';
import {
    inlineZoomInitClick,
    inlineZoomImageOnHover,
    inlineZoomImageOnMouseOut,
    inlineZoomImageOnMouseMove
} from '@msdyn365-commerce-modules/utilities';

interface IInlineZoomImageComponentProps {
    image: IImageData;
    imageSettings?: IImageSettings;
    imageZoomScale: number;
    index: number;
    context: ICoreContext<IGeneric<IAny>>;
    id: string;
    className: string;
}

// image: IImageData, imageSettings: IImageSettings, index: number
const InlineZoomImageComponent: React.FC<IInlineZoomImageComponentProps> = props => {
    const { index, image, className, imageZoomScale } = props;
    const [isImageZoomed, setIsImageZoomed] = React.useState(false);
    const [isMobileImageZoomed, setIsMobileImageZoomed] = React.useState(false);

    const _inlineZoomDivRef: Map<number, HTMLDivElement> = new Map();
    const defaultDataScale = imageZoomScale?.toString() || '4';

    const _refHandler = (index: number) => (divRef: HTMLDivElement) => {
        _inlineZoomDivRef.set(index, divRef);
    };

    const _onInlineImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
        if (window.innerWidth <= 768) {
            // $msv-breakpoint-m
            _handleMobileViewZoomedImageClick(event);
            return;
        }
        inlineZoomInitClick(event, defaultDataScale);
        setIsImageZoomed(true);
    };

    const _inlineZoomImageOnHover = (event: React.MouseEvent<HTMLImageElement>) => {
        inlineZoomImageOnHover(event, defaultDataScale);
    };

    const _onImageMouseOut = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        inlineZoomImageOnMouseOut(event);
        setIsImageZoomed(false);
    };

    const _handleMobileViewZoomedImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
        const target = event.currentTarget;
        const mobileZoomedInImageClassName = 'msc-mobile-zoomed-in';
        if (!isMobileImageZoomed) {
            const bounds = target.getBoundingClientRect();
            const dataScale = Number(defaultDataScale);

            const positionX = event.clientX - bounds.left;
            const positionY = event.clientY - bounds.top;
            const scaledPositionX = positionX * dataScale;
            const scaledPositionY = positionY * dataScale;

            target.style.transform = `scale(${dataScale})`;
            target.classList.add(mobileZoomedInImageClassName);
            target.parentElement!.style.overflow = 'auto';
            target.parentElement!.scrollTo(scaledPositionX - positionX, scaledPositionY - positionY);
            setIsMobileImageZoomed(true);
        } else {
            target.style.transform = '';
            target.classList.remove(mobileZoomedInImageClassName);
            target.parentElement!.style.overflow = '';
            setIsMobileImageZoomed(false);
        }
    };

    return (
        <div className={`ms-inline-zoom ${isImageZoomed ? 'zoomed' : ''}`} ref={_refHandler(index)} data-scale={defaultDataScale}>
            <Image
                requestContext={props.context.actionContext.requestContext}
                className={`ms-media-gallery__item ${className}`}
                {...image}
                fallBackSrc={image.src}
                gridSettings={props.context.request.gridSettings!}
                // imageSettings={imageSettings}
                loadFailureBehavior='hide'
                onClick={_onInlineImageClick}
                onMouseOver={(isImageZoomed && _inlineZoomImageOnHover) || undefined}
                id={`${props.id}__carousel-item__${index}`}
            />
            <Image
                role='presentation'
                requestContext={props.context.actionContext.requestContext}
                className={`ms-inline-zoom__zoomedImg ${className}`}
                {...image}
                fallBackSrc={image.src}
                onClick={_onImageMouseOut}
                gridSettings={props.context.request.gridSettings!}
                // imageSettings={imageSettings}
                loadFailureBehavior='hide'
                id={`${props.id}__zoom__${index}`}
                onMouseMove={(isImageZoomed && inlineZoomImageOnMouseMove) || undefined}
            />
        </div>
    );
};

export default InlineZoomImageComponent;

import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
interface DateEventTarget {
    name: string;
    value: string;
}
export interface DateEvent {
    target: DateEventTarget;
}
interface IAccountProfileInputProps {
    fieldKey: string;
    label: string;
    value: string;
    type: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | DateEvent) => void;
    dropdownValues?: string[];
}

export default function AccountProfileInput(props: IAccountProfileInputProps) {
    const { fieldKey, label, value, type, dropdownValues, onChange } = props;
    const [date, setDate] = React.useState<Date>(new Date());

    if (type === 'datepicker') {
        React.useEffect(() => {
            moment(value).isValid() && setDate(new Date(value));
        }, []);
    }

    let inputField = <input id={fieldKey} type={type} name={fieldKey} value={value} onChange={onChange} />;
    const handleDateChange = (date: Date) => {
        const event: DateEvent = {
            target: {
                name: fieldKey,
                value: moment(date).format('MM/DD/YYYY')
            }
        };
        setDate(date);
        onChange(event);
    };

    if (type === 'select') {
        inputField = (
            <select id={fieldKey} name={fieldKey} onChange={onChange} value={value}>
                {value === '' ? <option value=''>Please select {label}</option> : null}
                {dropdownValues &&
                    dropdownValues.map(val => {
                        return (
                            <option key={val} value={val}>
                                {val}
                            </option>
                        );
                    })}
            </select>
        );
    }
    if (type === 'datepicker') {
        inputField = (
            <DatePicker
                selected={date}
                dateFormat='dd/MM/yyyy'
                onChange={handleDateChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
            />
        );
    }

    return (
        <div className={`form-group ${fieldKey}`}>
            <label htmlFor={fieldKey}>{label}</label>
            {inputField}
        </div>
    );
}
